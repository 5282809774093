$waves: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjE1NSIgaGVpZ2h0PSI4NSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KIDxnPgogIDxyZWN0IGZpbGw9Im5vbmUiIGlkPSJjYW52YXNfYmFja2dyb3VuZCIgaGVpZ2h0PSI4NyIgd2lkdGg9IjE1NyIgeT0iLTEiIHg9Ii0xIi8+CiAgPGcgZGlzcGxheT0ibm9uZSIgb3ZlcmZsb3c9InZpc2libGUiIHk9IjAiIHg9IjAiIGhlaWdodD0iMTAwJSIgd2lkdGg9IjEwMCUiIGlkPSJjYW52YXNHcmlkIj4KICAgPHJlY3QgZmlsbD0idXJsKCNncmlkcGF0dGVybikiIHN0cm9rZS13aWR0aD0iMCIgeT0iMCIgeD0iMCIgaGVpZ2h0PSIxMDAlIiB3aWR0aD0iMTAwJSIvPgogIDwvZz4KIDwvZz4KIDxnPgogIDxnPgogICA8ZWxsaXBzZSByeT0iMjYiIHJ4PSIyNiIgaWQ9InN2Z18xIiBjeT0iMzMuNSIgY3g9IjM0LjUiIHN0cm9rZS13aWR0aD0iMS41IiBmaWxsPSIjZjRmNGZmIi8+CiAgIDxlbGxpcHNlIHJ5PSIyNiIgcng9IjI2IiBpZD0ic3ZnXzMiIGN5PSIzMy41IiBjeD0iNjMuODY4NDkzIiBzdHJva2Utd2lkdGg9IjEuNSIgZmlsbD0iI2Y0ZjRmZiIvPgogICA8ZWxsaXBzZSByeT0iMjYiIHJ4PSIyNiIgaWQ9InN2Z182IiBjeT0iMzMuNSIgY3g9IjkxLjcxODg4IiBzdHJva2Utd2lkdGg9IjEuNSIgZmlsbD0iI2Y0ZjRmZiIvPgogICA8ZWxsaXBzZSByeT0iMjYiIHJ4PSIyNiIgaWQ9InN2Z183IiBjeT0iMzMuNSIgY3g9IjEyMS4wODczNzMiIHN0cm9rZS13aWR0aD0iMS41IiBmaWxsPSIjZjRmNGZmIi8+CiAgIDxyZWN0IGlkPSJzdmdfOCIgaGVpZ2h0PSI0NiIgd2lkdGg9IjE0MC4wMDAwMDYiIHk9IjMyLjUiIHg9IjcuNSIgc3Ryb2tlLXdpZHRoPSIxLjUiIGZpbGw9IiNmNGY0ZmYiLz4KICA8L2c+CiA8L2c+Cjwvc3ZnPg==";

$colors: (
  accent: #ffc107,
  top-layer: #6c63ff,
  bottom-layer: #2b2b2b,
  hull: #6c63ff,
  waves: #f4f4ff// actually encoded in fill of $waves
);

#ferry {
  position: relative;
  margin: 12rem auto 6rem;
  display: inline-block;
  width: 64px;
  height: 32px;
  border-radius: 3px 0 0 0;
  background-color: map-get($colors, bottom-layer);
  z-index: 1;

  animation: bop 0.48s ease-in-out alternate infinite;

  @keyframes bop {
    from {
      transform: scale(2);
    }
    to {
      transform: translateY(-40%) scale(2);
    }
  }

  box-shadow: inset 0 -5px 0 0 map-get($colors, hull),
    inset 0 -15px 0 0 map-get($colors, bottom-layer),
    inset 0 -17px 0 0 map-get($colors, accent);

  border-top: 4px solid map-get($colors, top-layer);

  &:after {
    content: "";
    position: absolute;
    top: -18px;
    right: 0;
    display: block;
    width: 45px;
    height: 15px;
    background-color: map-get($colors, top-layer);
    z-index: 1;
    border-radius: 3px 3px 0 0;
  }

  &:before {
    content: "";
    position: absolute;
    transform: rotate(-25deg);
    top: -5px;
    left: -3px;
    width: 10px;
    height: 50px;
    background-color: map-get($colors, waves);
  }

  #chimney {
    position: absolute;
    top: -35px;
    display: block;
    width: 17px;
    height: 22px;
    background-color: map-get($colors, accent);
    left: 32px;
    z-index: -1;
    transform: rotate(8deg);
    box-shadow: inset 0 3px 0 0 map-get($colors, bottom-layer);
  }

  #waves {
    position: absolute;
    bottom: -37px;
    display: block;
    width: 100px;
    height: 50px;
    background-image: url($waves);
    background-size: 133% 144%;
    animation: sail 4s linear infinite;
    fill: map-get($colors, waves);

    @keyframes sail {
      to {
        background-position: 400px 0;
      }
    }
  }
}
