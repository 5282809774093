.login,
.signup {
  width: 100%;
  margin: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (min-width: 568px) {
    width: 465px;
    justify-content: center;
  }

  &__modal {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 568px) {
      border-radius: 8px;
      border: 1px solid #fff;
      box-shadow: 0 0.25rem 1rem 0 rgba(108, 99, 255, 0.2);
    }

    padding: 3.25rem;
    line-height: 1.5;
    color: #3d454d;
    background-color: #fdfefe;
    margin: 0 auto;
    transition: height 0.1s;

    h1 {
      text-align: center;
    }

    &__form {
      .error {
        color: #f93d3d;
        margin-bottom: 8px;
      }

      input {
        width: 100%;
        height: 41px;
        width: 100%;
        border: 1px solid #e0e2e3;
        height: 38px;
        margin: 0;
        padding-left: 10px;
        padding-right: 10px;
        outline: none;
        box-shadow: none;
        font-size: 100%;

        &[name="email"] {
          border-radius: 3px 3px 0 0;
        }
        &[name="password"] {
          border-radius: 0 0 3px 3px;
          border-top-width: 0;
        }
      }

      button {
        display: block;
        margin: 12px auto 0;
        line-height: 15px;
        height: auto;
        padding: 12px 16px;
        width: 100%;
        min-width: 80px;
        color: #fff;
        background-color: rgb(95, 90, 204); //#2996cc;
        transition: ease all 200ms;

        &:hover {
          background-color: rgb(20, 25, 66); //#3ba8de;
        }
      }
    }

    .redirect-text {
      margin-top: 24px;
      text-align: center;

      a {
        font-weight: bold;
      }
    }
  }
}
