@import url("https://fonts.googleapis.com/css?family=Lato:400,500,700|Playfair+Display:700");

$header-font: "Playfair Display", sans-serif;
$body-font: "Lato", sans-serif;

*,
:after,
:before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: $body-font;
  font-weight: 400;
}

html {
  background-color: #f4f4ff;
}

body,
html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  line-height: 21px;
  color: #454545;
  width: 100%;
  font-family: $body-font;
  font-size: 14px;
}

h1,
h2 {
  font-family: $header-font;
}

h1,
h2,
h3,
h4 {
  margin-bottom: 20px;
  font-weight: 400;
  font-style: normal;
}

h1,
h2,
h3 {
  line-height: 28px;
  font-size: 24px;
}
h4 {
  line-height: 25px;
  font-size: 21px;
}

@media (min-width: 769px) {
  body {
    line-height: 23px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
  }
  h1 {
    line-height: 52px;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
  }
  h2 {
    line-height: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
  }
  h3 {
    line-height: 34px;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
  }
  h4 {
    line-height: 28px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
  }
}

a,
a:visited {
  color: rgb(95, 90, 204);
  text-decoration: none;
}
a:hover,
a:visited:hover {
  text-decoration: underline;
}
a:hover .link-indicator,
a:visited:hover .link-indicator {
  -ms-transform: translateX(-5px);
  transform: translateX(-5px);
}

button {
  border-color: transparent;
  text-rendering: optimizelegibility;
  font-size: 100%;
}

dl,
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
