@import url(https://fonts.googleapis.com/css?family=Lato:400,500,700|Playfair+Display:700);
*,
:after,
:before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Lato", sans-serif;
  font-weight: 400; }

html {
  background-color: #f4f4ff; }

body,
html {
  height: 100%;
  scroll-behavior: smooth; }

body {
  line-height: 21px;
  color: #454545;
  width: 100%;
  font-family: "Lato", sans-serif;
  font-size: 14px; }

h1,
h2 {
  font-family: "Playfair Display", sans-serif; }

h1,
h2,
h3,
h4 {
  margin-bottom: 20px;
  font-weight: 400;
  font-style: normal; }

h1,
h2,
h3 {
  line-height: 28px;
  font-size: 24px; }

h4 {
  line-height: 25px;
  font-size: 21px; }

@media (min-width: 769px) {
  body {
    line-height: 23px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px; }
  h1 {
    line-height: 52px;
    font-style: normal;
    font-weight: 400;
    font-size: 48px; }
  h2 {
    line-height: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 36px; }
  h3 {
    line-height: 34px;
    font-style: normal;
    font-weight: 400;
    font-size: 30px; }
  h4 {
    line-height: 28px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px; } }

a,
a:visited {
  color: #5f5acc;
  text-decoration: none; }

a:hover,
a:visited:hover {
  text-decoration: underline; }

a:hover .link-indicator,
a:visited:hover .link-indicator {
  -webkit-transform: translateX(-5px);
          transform: translateX(-5px); }

button {
  border-color: transparent;
  text-rendering: optimizelegibility;
  font-size: 100%; }

dl,
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.home__sidebar {
  display: block;
  position: fixed;
  width: 234px;
  height: 100vh;
  background-color: #141942;
  flex-direction: column;
  flex-wrap: nowrap; }
  .home__sidebar__top {
    padding: 1.5rem; }
    .home__sidebar__top .merchant {
      padding: 0;
      font-weight: 500;
      line-height: 1.4;
      color: #fff;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .home__sidebar__top .logout {
      color: #c2c7cc;
      line-height: 1.4;
      text-decoration: none;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }

.home__content {
  margin-left: 234px;
  padding: 2rem 2rem 0; }
  .home__content .welcome-message,
  .home__content .no-queue-message {
    display: block;
    max-width: 600px;
    width: 90%; }
  .home__content .no-queue-message .accent {
    font-family: "Playfair Display", sans-serif; }
  .home__content .no-queue-message p {
    margin-bottom: 12px; }
    .home__content .no-queue-message p:last-of-type {
      margin-bottom: 0; }
  .home__content__queues header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 1.15rem; }
    .home__content__queues header .title {
      margin-bottom: 4px; }
    .home__content__queues header .actions .create-queue {
      position: relative;
      box-sizing: border-box;
      border-radius: 50%;
      border: 2px solid #141942;
      height: 30px;
      width: 30px;
      margin: 0 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none; }
      .home__content__queues header .actions .create-queue .plus-icon {
        color: #141942;
        font-size: 20px; }
  .home__content__queues .queue-location {
    margin-bottom: 1rem; }
    .home__content__queues .queue-location__name {
      margin-bottom: 0; }
    .home__content__queues .queue-location .queue-list {
      display: flex;
      flex-flow: row wrap; }
      .home__content__queues .queue-location .queue-list__item {
        position: relative;
        margin: 1.15rem 2.3rem 1.15rem 0;
        width: 180px;
        height: 180px;
        background: #fff;
        border-radius: 8px;
        cursor: pointer;
        box-shadow: 0 0.25rem 0.5rem 0 rgba(108, 99, 255, 0.2);
        transition: 200ms ease all; }
        .home__content__queues .queue-location .queue-list__item:hover, .home__content__queues .queue-location .queue-list__item:active {
          -webkit-transform: translateY(-0.3rem);
                  transform: translateY(-0.3rem);
          box-shadow: 0 0.3rem 0.85rem 0.1rem #e5e3ff; }
        .home__content__queues .queue-location .queue-list__item a {
          text-decoration: none;
          position: absolute;
          height: 100%;
          width: 100%;
          color: #5f5acc; }
          .home__content__queues .queue-location .queue-list__item a:hover {
            text-decoration: none; }
        .home__content__queues .queue-location .queue-list__item .abbr {
          margin-top: 48px;
          font-size: 68px;
          text-align: center; }
        .home__content__queues .queue-location .queue-list__item .full-name {
          text-align: center; }
        .home__content__queues .queue-location .queue-list__item--placeholder {
          cursor: not-allowed; }
          .home__content__queues .queue-location .queue-list__item--placeholder:hover, .home__content__queues .queue-location .queue-list__item--placeholder:active {
            -webkit-transform: translateY(0);
                    transform: translateY(0); }
          .home__content__queues .queue-location .queue-list__item--placeholder .abbr,
          .home__content__queues .queue-location .queue-list__item--placeholder .full-name {
            color: #aaa; }

#ferry {
  position: relative;
  margin: 12rem auto 6rem;
  display: inline-block;
  width: 64px;
  height: 32px;
  border-radius: 3px 0 0 0;
  background-color: #2b2b2b;
  z-index: 1;
  -webkit-animation: bop 0.48s ease-in-out alternate infinite;
          animation: bop 0.48s ease-in-out alternate infinite;
  box-shadow: inset 0 -5px 0 0 #6c63ff, inset 0 -15px 0 0 #2b2b2b, inset 0 -17px 0 0 #ffc107;
  border-top: 4px solid #6c63ff; }

@-webkit-keyframes bop {
  from {
    -webkit-transform: scale(2);
            transform: scale(2); }
  to {
    -webkit-transform: translateY(-40%) scale(2);
            transform: translateY(-40%) scale(2); } }

@keyframes bop {
  from {
    -webkit-transform: scale(2);
            transform: scale(2); }
  to {
    -webkit-transform: translateY(-40%) scale(2);
            transform: translateY(-40%) scale(2); } }
  #ferry:after {
    content: "";
    position: absolute;
    top: -18px;
    right: 0;
    display: block;
    width: 45px;
    height: 15px;
    background-color: #6c63ff;
    z-index: 1;
    border-radius: 3px 3px 0 0; }
  #ferry:before {
    content: "";
    position: absolute;
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg);
    top: -5px;
    left: -3px;
    width: 10px;
    height: 50px;
    background-color: #f4f4ff; }
  #ferry #chimney {
    position: absolute;
    top: -35px;
    display: block;
    width: 17px;
    height: 22px;
    background-color: #ffc107;
    left: 32px;
    z-index: -1;
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
    box-shadow: inset 0 3px 0 0 #2b2b2b; }
  #ferry #waves {
    position: absolute;
    bottom: -37px;
    display: block;
    width: 100px;
    height: 50px;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjE1NSIgaGVpZ2h0PSI4NSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KIDxnPgogIDxyZWN0IGZpbGw9Im5vbmUiIGlkPSJjYW52YXNfYmFja2dyb3VuZCIgaGVpZ2h0PSI4NyIgd2lkdGg9IjE1NyIgeT0iLTEiIHg9Ii0xIi8+CiAgPGcgZGlzcGxheT0ibm9uZSIgb3ZlcmZsb3c9InZpc2libGUiIHk9IjAiIHg9IjAiIGhlaWdodD0iMTAwJSIgd2lkdGg9IjEwMCUiIGlkPSJjYW52YXNHcmlkIj4KICAgPHJlY3QgZmlsbD0idXJsKCNncmlkcGF0dGVybikiIHN0cm9rZS13aWR0aD0iMCIgeT0iMCIgeD0iMCIgaGVpZ2h0PSIxMDAlIiB3aWR0aD0iMTAwJSIvPgogIDwvZz4KIDwvZz4KIDxnPgogIDxnPgogICA8ZWxsaXBzZSByeT0iMjYiIHJ4PSIyNiIgaWQ9InN2Z18xIiBjeT0iMzMuNSIgY3g9IjM0LjUiIHN0cm9rZS13aWR0aD0iMS41IiBmaWxsPSIjZjRmNGZmIi8+CiAgIDxlbGxpcHNlIHJ5PSIyNiIgcng9IjI2IiBpZD0ic3ZnXzMiIGN5PSIzMy41IiBjeD0iNjMuODY4NDkzIiBzdHJva2Utd2lkdGg9IjEuNSIgZmlsbD0iI2Y0ZjRmZiIvPgogICA8ZWxsaXBzZSByeT0iMjYiIHJ4PSIyNiIgaWQ9InN2Z182IiBjeT0iMzMuNSIgY3g9IjkxLjcxODg4IiBzdHJva2Utd2lkdGg9IjEuNSIgZmlsbD0iI2Y0ZjRmZiIvPgogICA8ZWxsaXBzZSByeT0iMjYiIHJ4PSIyNiIgaWQ9InN2Z183IiBjeT0iMzMuNSIgY3g9IjEyMS4wODczNzMiIHN0cm9rZS13aWR0aD0iMS41IiBmaWxsPSIjZjRmNGZmIi8+CiAgIDxyZWN0IGlkPSJzdmdfOCIgaGVpZ2h0PSI0NiIgd2lkdGg9IjE0MC4wMDAwMDYiIHk9IjMyLjUiIHg9IjcuNSIgc3Ryb2tlLXdpZHRoPSIxLjUiIGZpbGw9IiNmNGY0ZmYiLz4KICA8L2c+CiA8L2c+Cjwvc3ZnPg==");
    background-size: 133% 144%;
    -webkit-animation: sail 4s linear infinite;
            animation: sail 4s linear infinite;
    fill: #f4f4ff; }

@-webkit-keyframes sail {
  to {
    background-position: 400px 0; } }

@keyframes sail {
  to {
    background-position: 400px 0; } }

.arrow-icon {
  position: relative;
  top: -1px;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  vertical-align: middle;
  cursor: pointer; }
  .arrow-icon--up {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  .arrow-icon--down {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .arrow-icon--left, .arrow-icon--back {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .arrow-icon__arrow, .arrow-icon__circle {
    stroke: #141942;
    fill: #fff; }
  .arrow-icon__circle {
    transition: stroke-dashoffset 0.3s ease;
    stroke-dasharray: 95;
    stroke-dashoffset: 95; }
  .arrow-icon:hover .arrow-icon {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
    .arrow-icon:hover .arrow-icon__circle {
      stroke-dashoffset: 0; }

.queue__header {
  padding: 0.85rem 1.5rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  display: flex;
  align-items: center; }
  .queue__header__name {
    margin: 0 0 0 1rem;
    font-weight: bold;
    line-height: 36px;
    font-size: 28px; }

.queue__main section {
  max-width: 65%; }

.queue__main .no-content {
  max-width: 520px;
  margin: auto; }
  .queue__main .no-content section {
    max-width: 100%; }
  .queue__main .no-content button {
    display: block;
    margin: 0.5rem auto 0;
    max-width: 260px; }

.ticket-list {
  display: flex;
  flex-flow: row nowrap;
  overflow-x: scroll;
  align-items: baseline;
  padding: 1.5rem; }
  .ticket-list:empty {
    height: 0;
    padding: 0; }

.ticket {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 240px;
  height: 82vh;
  text-decoration: none;
  background: #fff;
  transition: all 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  margin: 0 1.5rem 0 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 0.25rem 1rem 0 rgba(108, 99, 255, 0.2); }
  .ticket .scroll-tracker {
    position: absolute;
    left: 0;
    height: 2px;
    background-color: #ede4f5; }
  .ticket__header {
    padding: 0.75rem 0.75rem 0;
    padding-bottom: 0; }
    .ticket__header__name {
      font-size: 1.15rem;
      line-height: 1.15rem;
      margin: 0 0 0.5rem;
      font-weight: bold; }
    .ticket__header__details {
      font-size: 1rem;
      color: #141942; }
    .ticket__header:after {
      content: "";
      width: 80%;
      flex: 1 0 100%;
      height: 2px;
      background: #ede4f5;
      margin-top: 0.75rem; }
  .ticket__content {
    padding: 0 0.75rem 0.75rem;
    height: 85%;
    min-width: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth; }
    .ticket__content::-webkit-scrollbar {
      display: none; }
    .ticket__content .up-marker,
    .ticket__content .down-marker {
      position: absolute;
      z-index: 2; }
    .ticket__content__items {
      list-style: none; }
      .ticket__content__items .item {
        margin-bottom: 0.75rem;
        line-height: 1.15rem; }
        .ticket__content__items .item:last-of-type {
          margin-right: 1.5rem; }
        .ticket__content__items .item__label {
          font-size: 1.15rem;
          margin-bottom: 4px;
          color: #454545;
          font-weight: bold; }
        .ticket__content__items .item__detail {
          display: flex;
          align-items: baseline;
          font-size: 1rem;
          line-height: 1.15rem;
          margin: 0 0 0.5rem 0.5rem; }
          .ticket__content__items .item__detail--quantity p {
            display: flex;
            flex-direction: row; }
            .ticket__content__items .item__detail--quantity p strong {
              margin-bottom: 0; }
          .ticket__content__items .item__detail p {
            color: #141942; }
            .ticket__content__items .item__detail p .label {
              color: #454545; }
  .ticket__footer {
    padding: 0 0.75rem 0.75rem 0.75rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between; }
    .ticket__footer:before {
      content: "";
      width: 80%;
      flex: 1 0 100%;
      height: 2px;
      background: #ede4f5;
      margin-bottom: 0.75rem; }
    .ticket__footer .complete-button {
      display: inline-block;
      min-height: 1em;
      outline: 0;
      border: none;
      vertical-align: baseline;
      margin: 0 0.25rem 0 0;
      padding: 0.75rem 1.5rem 0.775rem;
      text-transform: none;
      text-shadow: none;
      font-size: 1.15rem;
      font-weight: 700;
      line-height: 1em;
      text-align: center;
      text-decoration: none;
      background-color: #1abc88;
      color: #fff;
      text-shadow: none;
      background-image: none;
      box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
      transition: all 200ms ease; }
      .ticket__footer .complete-button:hover:not([disabled]) {
        background-color: #02a468;
        color: #fff;
        text-shadow: none; }
      .ticket__footer .complete-button:active:not([disabled]) {
        -webkit-transform: translateY(1px);
                transform: translateY(1px); }
    .ticket__footer .table-number {
      align-items: center;
      border-radius: 50%;
      box-sizing: border-box;
      background: #4a00e0;
      background: linear-gradient(45deg, #8e2de2 0%, #4a00e0 100%);
      color: #fff;
      display: inline-flex;
      justify-content: center;
      position: relative;
      vertical-align: top;
      font-size: 18px;
      height: 42px;
      line-height: 42px;
      width: 42px;
      margin: 0; }

.ticket-animation-enter.ticket-animation-enter-active {
  -webkit-animation: enter 850ms;
          animation: enter 850ms; }

@-webkit-keyframes enter {
  0% {
    opacity: 0;
    width: 0;
    margin: 0; }
  100% {
    opacity: 1;
    width: 238px;
    margin-right: 1.5rem; } }

@keyframes enter {
  0% {
    opacity: 0;
    width: 0;
    margin: 0; }
  100% {
    opacity: 1;
    width: 238px;
    margin-right: 1.5rem; } }

.ticket-animation-exit .ticket__header, .ticket-animation-exit .ticket__content, .ticket-animation-exit .ticket__footer {
  overflow: hidden;
  -webkit-animation: complete-halfway 1650ms;
          animation: complete-halfway 1650ms; }

.ticket-animation-exit-active {
  -webkit-animation: complete 1650ms;
          animation: complete 1650ms; }
  .ticket-animation-exit-active:before {
    position: absolute;
    content: "";
    z-index: 3;
    height: 100%;
    background: #00cdac;
    background: linear-gradient(-90deg, #1abc88 0%, #00cdac 100%);
    border-radius: 8px;
    -webkit-animation: complete-overlay 1650ms;
            animation: complete-overlay 1650ms; }

@-webkit-keyframes complete {
  0% {
    max-width: 238px; }
  50% {
    opacity: 1;
    max-width: 238px;
    margin-right: 1.75rem;
    margin-left: 0.25rem; }
  100% {
    opacity: 0;
    max-width: 0;
    margin: 0; } }

@keyframes complete {
  0% {
    max-width: 238px; }
  50% {
    opacity: 1;
    max-width: 238px;
    margin-right: 1.75rem;
    margin-left: 0.25rem; }
  100% {
    opacity: 0;
    max-width: 0;
    margin: 0; } }

@-webkit-keyframes complete-halfway {
  0% { }
  40% {
    opacity: 0.8; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes complete-halfway {
  0% { }
  40% {
    opacity: 0.8; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes complete-overlay {
  0% {
    opacity: 0.8;
    width: 5px; }
  50% {
    width: 238px;
    opacity: 1; }
  100% {
    width: 0; } }

@keyframes complete-overlay {
  0% {
    opacity: 0.8;
    width: 5px; }
  50% {
    width: 238px;
    opacity: 1; }
  100% {
    width: 0; } }

.headerbar {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  flex: none;
  align-items: center;
  padding: 16px 32px;
  z-index: 10;
  background: #fff; }
  .headerbar__content {
    flex: 0 10 240px;
    position: relative;
    white-space: nowrap; }
    .headerbar__content__nav {
      display: flex;
      align-items: center; }
      .headerbar__content__nav--left .headerbar__content__nav__item {
        margin-right: 16px; }
  .headerbar__title {
    flex: 1 0 auto;
    margin: 0;
    padding: 0 16px;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    align-self: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 36px; }

form {
  max-width: 660px;
  margin: 2rem 1rem; }
  @media (min-width: 660px) {
    form {
      margin: 2rem auto; } }
  form h4 {
    margin-bottom: 0; }

.create-queue-form__actions {
  display: flex;
  flex-direction: column;
  margin: auto; }
  .create-queue-form__actions button {
    max-width: unset;
    flex: 1 1;
    margin: 0.5rem 0; }

fieldset {
  border: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }
  fieldset legend {
    margin: 0;
    color: #333b43;
    font-size: 18px;
    letter-spacing: inherit;
    line-height: 1.333;
    text-transform: none; }

.input-group,
.input-field {
  margin-bottom: 1rem; }
  .input-group .error fieldset legend,
  .input-field .error fieldset legend {
    color: #f93d3d; }
  .input-group .no-option-message,
  .input-field .no-option-message {
    display: block;
    margin: 1rem 0 0.5rem 1rem;
    width: 100%;
    color: #a2a2a2; }

.text-input {
  padding: 0.5rem;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin: 0.5rem 0; }

.radio-button,
.checkbox {
  padding: 0.5rem;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin: 0.5rem 0;
  flex: 0 0 48%;
  background: #fff; }
  .radio-button input,
  .checkbox input {
    margin-right: 0.5rem; }
  .radio-button--placeholder,
  .checkbox--placeholder {
    position: relative; }
    .radio-button--placeholder span,
    .checkbox--placeholder span {
      position: absolute;
      background-color: #ebedef;
      height: 19px;
      top: 12px;
      left: 30px;
      max-width: 80%; }

.text-input:focus,
.checkbox.checked,
.radio-button.checked {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none; }
  .text-input:focus.disabled,
  .checkbox.checked.disabled,
  .radio-button.checked.disabled {
    border-color: #a2a2a2;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(127, 126, 200, 0.1); }

.error .text-input {
  border-color: #f93d3d; }

.label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem; }

.error .label {
  color: #f93d3d; }

.input-feedback {
  color: #999;
  margin: 0.25rem 0 0 0.5rem; }
  .input-feedback.error {
    color: #f93d3d; }

button {
  max-width: 180px;
  margin: 20px 0;
  padding: 12px 20px;
  border-style: none;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 17px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  background-color: #5f5acc;
  transition: ease all 200ms; }
  button:hover {
    background-color: #141942; }

button:disabled {
  opacity: 0.5;
  cursor: not-allowed !important; }

button + button {
  margin-left: 0.5rem; }

button.outline {
  background-color: #eee;
  border: 1px solid #aaa;
  color: #555; }

.login,
.signup {
  width: 100%;
  margin: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }
  @media (min-width: 568px) {
    .login,
    .signup {
      width: 465px;
      justify-content: center; } }
  .login__modal,
  .signup__modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 3.25rem;
    line-height: 1.5;
    color: #3d454d;
    background-color: #fdfefe;
    margin: 0 auto;
    transition: height 0.1s; }
    @media (min-width: 568px) {
      .login__modal,
      .signup__modal {
        border-radius: 8px;
        border: 1px solid #fff;
        box-shadow: 0 0.25rem 1rem 0 rgba(108, 99, 255, 0.2); } }
    .login__modal h1,
    .signup__modal h1 {
      text-align: center; }
    .login__modal__form .error,
    .signup__modal__form .error {
      color: #f93d3d;
      margin-bottom: 8px; }
    .login__modal__form input,
    .signup__modal__form input {
      width: 100%;
      height: 41px;
      width: 100%;
      border: 1px solid #e0e2e3;
      height: 38px;
      margin: 0;
      padding-left: 10px;
      padding-right: 10px;
      outline: none;
      box-shadow: none;
      font-size: 100%; }
      .login__modal__form input[name="email"],
      .signup__modal__form input[name="email"] {
        border-radius: 3px 3px 0 0; }
      .login__modal__form input[name="password"],
      .signup__modal__form input[name="password"] {
        border-radius: 0 0 3px 3px;
        border-top-width: 0; }
    .login__modal__form button,
    .signup__modal__form button {
      display: block;
      margin: 12px auto 0;
      line-height: 15px;
      height: auto;
      padding: 12px 16px;
      width: 100%;
      min-width: 80px;
      color: #fff;
      background-color: #5f5acc;
      transition: ease all 200ms; }
      .login__modal__form button:hover,
      .signup__modal__form button:hover {
        background-color: #141942; }
    .login__modal .redirect-text,
    .signup__modal .redirect-text {
      margin-top: 24px;
      text-align: center; }
      .login__modal .redirect-text a,
      .signup__modal .redirect-text a {
        font-weight: bold; }

