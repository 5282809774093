$ticket-padding: 0.75rem;
$ticket-margin: 1.5rem;
$label-color: #454545;
$detail-color: rgb(20, 25, 66);

.arrow-icon {
  position: relative;
  top: -1px;
  transition: transform 0.3s ease;
  vertical-align: middle;
  cursor: pointer;

  &--up {
    transform: rotate(-90deg);
  }
  &--down {
    transform: rotate(90deg);
  }
  &--left,
  &--back {
    transform: rotate(180deg);
  }

  &__arrow,
  &__circle {
    stroke: $detail-color;
    fill: #fff;
  }

  &__circle {
    transition: stroke-dashoffset 0.3s ease;
    stroke-dasharray: 95;
    stroke-dashoffset: 95;

    //  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }

  &:hover {
    .arrow-icon {
      transform: translate3d(5px, 0, 0);

      &__circle {
        stroke-dashoffset: 0;
      }
    }
  }
}

.queue {
  &__header {
    padding: 0.85rem $ticket-margin;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    display: flex;
    align-items: center;

    &__name {
      margin: 0 0 0 1rem;
      font-weight: bold;
      line-height: 36px;
      font-size: 28px;
    }
  }
  &__main {
    section {
      // used for loaders, no-content
      max-width: 65%;
    }

    // hacked for seed-data
    .no-content {
      max-width: 520px;
      margin: auto;

      section {
        max-width: 100%;
      }

      button {
        display: block;
        margin: 0.5rem auto 0;
        max-width: 260px;
      }
    }
  }
}

.ticket-list {
  display: flex;
  flex-flow: row nowrap;
  overflow-x: scroll;
  align-items: baseline;
  padding: $ticket-margin;

  &:empty {
    height: 0;
    padding: 0;
  }
}

.ticket {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 240px;
  height: 82vh;
  text-decoration: none;
  background: #fff;
  transition: all 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  margin: 0 $ticket-margin 0 0;
  user-select: none;
  position: relative; // used for up-/down- markers in __content
  border-radius: 8px;
  box-shadow: 0 0.25rem 1rem 0 rgba(108, 99, 255, 0.2);

  .scroll-tracker {
    position: absolute;
    left: 0;
    height: 2px;
    background-color: #ede4f5;
  }

  &__header {
    padding: $ticket-padding $ticket-padding 0;
    padding-bottom: 0;
    &__name {
      font-size: 1.15rem;
      line-height: 1.15rem;
      margin: 0 0 0.5rem;
      font-weight: bold;
    }
    &__details {
      font-size: 1rem;
      color: $detail-color;
    }
    &:after {
      content: "";
      width: 80%;
      flex: 1 0 100%;
      height: 2px;
      background: #ede4f5;
      margin-top: $ticket-padding;
    }
  }

  &__content {
    padding: 0 $ticket-padding $ticket-padding;
    height: 85%;
    min-width: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }

    // postioning passed as measured style prop in JS
    .up-marker,
    .down-marker {
      position: absolute;
      z-index: 2;
    }

    &__items {
      list-style: none;

      .item {
        margin-bottom: $ticket-padding;
        line-height: 1.15rem;

        &:last-of-type {
          margin-right: $ticket-margin;
        }

        &__label {
          font-size: 1.15rem;
          margin-bottom: 4px;
          color: $label-color;
          font-weight: bold;
        }

        &__detail {
          display: flex;
          align-items: baseline;
          font-size: 1rem;
          line-height: 1.15rem;
          margin: 0 0 0.5rem 0.5rem;

          &--quantity {
            p {
              display: flex;
              flex-direction: row;
              strong {
                margin-bottom: 0; // __detail is enough as inline
              }
            }
          }

          p {
            color: $detail-color;

            .label {
              color: $label-color;
            }
          }
        }
      }
    }
  }

  &__footer {
    padding: 0 $ticket-padding $ticket-padding $ticket-padding;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &:before {
      content: "";
      width: 80%;
      flex: 1 0 100%;
      height: 2px;
      background: #ede4f5;
      margin-bottom: $ticket-padding;
    }

    .complete-button {
      display: inline-block;
      min-height: 1em;
      outline: 0;
      border: none;
      vertical-align: baseline;
      margin: 0 0.25rem 0 0;
      padding: 0.75rem 1.5rem 0.775rem;
      text-transform: none;
      text-shadow: none;
      font-size: 1.15rem;
      font-weight: 700;
      line-height: 1em;
      text-align: center;
      text-decoration: none;
      background-color: #1abc88;
      color: #fff;
      text-shadow: none;
      background-image: none;
      box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
      transition: all 200ms ease;

      &:hover:not([disabled]) {
        background-color: #02a468; //#1678c2; //blue
        color: #fff;
        text-shadow: none;
      }

      &:active:not([disabled]) {
        transform: translateY(1px);
      }
    }

    .table-number {
      align-items: center;
      border-radius: 50%;
      box-sizing: border-box;
      background: #4a00e0;
      background: linear-gradient(45deg, #8e2de2 0%, #4a00e0 100%);
      color: #fff;
      display: inline-flex;
      justify-content: center;
      position: relative;
      vertical-align: top;
      font-size: 18px;
      height: 42px;
      line-height: 42px;
      width: 42px;
      margin: 0;
    }
  }
}

.ticket-animation-enter {
  &.ticket-animation-enter-active {
    animation: enter 850ms;
  }
}

@keyframes enter {
  0% {
    opacity: 0;
    width: 0;
    margin: 0;
  }
  100% {
    opacity: 1;
    width: 238px;
    margin-right: $ticket-margin;
  }
}

.ticket-animation-exit {
  .ticket {
    &__header,
    &__content,
    &__footer {
      overflow: hidden; // ensures scroll bar away in FF
      animation: complete-halfway 1650ms;
    }
  }
}

.ticket-animation-exit-active {
  animation: complete 1650ms;

  &:before {
    position: absolute;
    content: "";
    z-index: 3;
    height: 100%;
    background: #00cdac;
    background: linear-gradient(-90deg, #1abc88 0%, #00cdac 100%);
    border-radius: 8px;
    animation: complete-overlay 1650ms;
  }
}

@keyframes complete {
  0% {
    max-width: 238px;
  }
  50% {
    opacity: 1;
    max-width: 238px;
    margin-right: 1.75rem;
    margin-left: 0.25rem;
  }
  100% {
    opacity: 0;
    max-width: 0;
    margin: 0;
  }
}

@keyframes complete-halfway {
  0% {
  }
  40% {
    opacity: 0.8;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes complete-overlay {
  0% {
    opacity: 0.8;
    width: 5px;
  }
  50% {
    width: 238px;
    opacity: 1;
  }
  100% {
    width: 0;
  }
}
