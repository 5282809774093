.home {
  &__sidebar {
    display: block;
    position: fixed;
    width: 234px;
    height: 100vh;
    background-color: rgb(20, 25, 66); //rgb(61, 69, 77);

    flex-direction: column;
    flex-wrap: nowrap;

    &__top {
      padding: 1.5rem;

      .merchant {
        padding: 0;
        font-weight: 500;
        line-height: 1.4;
        color: #fff;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .logout {
        color: #c2c7cc;
        line-height: 1.4;
        text-decoration: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &__content {
    margin-left: 234px; // matches __sidebar
    padding: 2rem 2rem 0;

    .welcome-message,
    .no-queue-message {
      display: block;
      max-width: 600px;
      width: 90%;
    }

    .no-queue-message {
      .accent {
        font-family: "Playfair Display", sans-serif;
      }
      p {
        margin-bottom: 12px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &__queues {
      header {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 1.15rem;

        .title {
          margin-bottom: 4px;
        }
        .actions {
          .create-queue {
            position: relative;
            box-sizing: border-box;
            border-radius: 50%;
            border: 2px solid rgb(20, 25, 66);
            height: 30px;
            width: 30px;
            margin: 0 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .plus-icon {
              color: rgb(20, 25, 66);
              font-size: 20px;
            }
          }
        }
      }

      .queue-location {
        margin-bottom: 1rem;

        &__name {
          margin-bottom: 0;
        }

        .queue-list {
          display: flex;
          flex-flow: row wrap;

          &__item {
            position: relative;
            margin: 1.15rem 2.3rem 1.15rem 0;
            width: 180px;
            height: 180px;
            background: #fff;
            //border: 1px solid #ccc;
            border-radius: 8px;
            cursor: pointer;
            box-shadow: 0 0.25rem 0.5rem 0 rgba(108, 99, 255, 0.2);
            transition: 200ms ease all;

            &:hover,
            &:active {
              transform: translateY(-0.3rem);
              box-shadow: 0 0.3rem 0.85rem 0.1rem #e5e3ff;
            }

            a {
              text-decoration: none;
              position: absolute;
              height: 100%;
              width: 100%;
              color: rgb(95, 90, 204);

              &:hover {
                text-decoration: none;
              }
            }

            .abbr {
              margin-top: 48px;
              font-size: 68px;
              text-align: center;
            }

            .full-name {
              text-align: center;
            }

            &--placeholder {
              cursor: not-allowed;
              &:hover,
              &:active {
                transform: translateY(0);
              }

              .abbr,
              .full-name {
                color: #aaa;
              }
            }
          }
        }
      }
    }
  }
}
