.headerbar {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  flex: none;
  align-items: center;
  padding: 16px 32px;
  z-index: 10;
  background: #fff;

  &__content {
    flex: 0 10 240px;
    position: relative;
    white-space: nowrap;

    &__nav {
      display: flex;
      align-items: center;

      &--left {
        .headerbar__content__nav__item {
          margin-right: 16px;
        }
      }
    }
  }

  &__title {
    flex: 1 0 auto;
    margin: 0;
    padding: 0 16px;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    align-self: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 36px;
  }
}

form {
  max-width: 660px;
  margin: 2rem 1rem;

  @media (min-width: 660px) {
    margin: 2rem auto;
  }

  h4 {
    margin-bottom: 0;
  }
}

.create-queue-form {
  &__actions {
    display: flex;
    flex-direction: column;
    margin: auto;

    button {
      max-width: unset;
      flex: 1;
      margin: 0.5rem 0;
    }
  }
}

fieldset {
  border: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  legend {
    margin: 0;
    color: #333b43;
    font-size: 18px;
    letter-spacing: inherit;
    line-height: 1.333;
    text-transform: none;
  }
}

.input-group,
.input-field {
  margin-bottom: 1rem;

  .error {
    fieldset {
      legend {
        color: #f93d3d;
      }
    }
  }

  .no-option-message {
    display: block;
    margin: 1rem 0 0.5rem 1rem;
    width: 100%;
    color: #a2a2a2;
  }
}

.text-input {
  padding: 0.5rem;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin: 0.5rem 0;
}

.radio-button,
.checkbox {
  padding: 0.5rem;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin: 0.5rem 0;
  flex: 0 0 48%;
  background: #fff;

  input {
    margin-right: 0.5rem;
  }

  &--placeholder {
    position: relative;

    span {
      position: absolute;
      background-color: #ebedef;
      height: 19px;
      top: 12px;
      left: 30px;
      max-width: 80%;
    }
  }
}

.text-input:focus,
.checkbox.checked,
.radio-button.checked {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;

  &.disabled {
    border-color: #a2a2a2;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(127, 126, 200, 0.1);
  }
}

.error .text-input {
  border-color: #f93d3d;
}

.label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.error .label {
  color: #f93d3d;
}

.input-feedback {
  color: #999;
  margin: 0.25rem 0 0 0.5rem;

  &.error {
    color: #f93d3d;
  }
}

button {
  max-width: 180px;
  margin: 20px 0;
  padding: 12px 20px;
  border-style: none;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 17px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  background-color: rgb(95, 90, 204); //#2996cc;
  transition: ease all 200ms;

  &:hover {
    background-color: rgb(20, 25, 66); //#3ba8de;
  }
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

button + button {
  margin-left: 0.5rem;
}

button.outline {
  background-color: #eee;
  border: 1px solid #aaa;
  color: #555;
}
